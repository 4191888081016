<script setup>
import { useCommon } from "@/store";
import {useFeatureFlags} from '~/store/featureFlags'

const localePath = useLocalePath();

const showSubscribePage = computed(() => {
  return useFeatureFlags().getFeatureFlags.website_subscription_page;
});
</script>

<template>
  <div class="less-effort-and-managment">
    <h2
      class="xl:text-center text-secondary-800 font-bold"
      v-text="$t('LESSEFFORTANDMANAGMENT')"
    />
    <div
      class="container mt-4 xl:mt-12 grid grid-cols-1 xl:grid-cols-3 xl:px-28"
    >
      <div class="xl:col-span-2 body-1">
        <div v-sanitize-html="$t('WETAKEPERFORMANCESERIOUSLY')" />
        <div class="mt-2 flex items-center">
          <span class="w-3 h-3 bg-primary-900 rounded-full" />
          <span
            class="ml-2 xl:ml-3"
            v-text="$t('FASTER')"
          />
        </div>
        <div class="mt-1 xl:mt-2 flex items-center">
          <span class="w-3 h-3 bg-primary-900 rounded-full" />
          <span
            class="ml-2 xl:ml-3"
            v-text="$t('AUTOMATED')"
          />
        </div>
        <p
          class="mt-2 font-medium xl:font-semibold"
          v-text="$t('IFYOUWANTTOSAVE')"
        />
        <div>
          <nuxt-link
            :to="
              localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })
            "
          >
            <sc-button
              class="mt-6 xl:mt-8"
              primary
              :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
            />
          </nuxt-link>
        </div>
      </div>
      <div class="hidden xl:block">
        <img src="/images/website_home_less_effort.png">
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.less-effort-and-managment {
  @apply py-8 xl:py-12 px-4 xl:px-0 bg-success-200;
  border-radius: rem(25px);
}

img {
  @apply ml-auto;
  width: rem(316px);
  height: rem(316px);
}
</style>
