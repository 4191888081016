<script setup>
import { useCommon } from "@/store";

const showSubscribePage = computed(async () => {
  return useFeatureFlags().getFeatureFlags.website_subscription_page;
});
const showDeveloperForDeveloper = computed(() => {
  return useFeatureFlags().getFeatureFlags.home_from_developer_for_developer;
});
const showWhatWillItCost = computed(() => {
  return useFeatureFlags().getFeatureFlags.home_what_will_it_cost;
});

const title = ref("A headless software, eCommerce platform | ShopCircuit");
const description = ref(
  "Ecommerce headless software helps you manage any number of storefronts, apps, and more. ShopCircuit has all the tools necessary to support any online business."
);
const url = ref("https://shop-circuit.ch/");
const image = ref("https://shop-circuit.ch/index.jpg");

useHead({
  title,
  description,
  meta: [
    { name: "Specific service page", content: description },
    { hid: "description", name: "description", content: description },
    { hid: "og:title", property: "og:title", content: title },
    { hid: "og:url", property: "og:url", content: url },
    { hid: "og:description", property: "og:description", content: description },
    { hid: "og:image", property: "og:image", content: image },

    { hid: "twitter:title", name: "twitter:title", content: title },
    { hid: "twitter:url", name: "twitter:url", content: url },
    {
      hid: "twitter:description",
      name: "twitter:description",
      content: description,
    },
    { hid: "twitter:image", name: "twitter:image", content: image },
  ],
  link: [{ rel: "canonical", href: url }],
});
</script>

<template>
  <div class="home">
    <div class="container">
      <home-header class="mt-8 lg:mt-12" />

      <what-can-you-do class="mt-16 lg:my-24" />
    </div>

    <who-is-it-for class="mt-16 lg:mt-24" />

    <div class="container">
      <how-do-i-get-started
        v-if="!showSubscribePage"
        class="mt-16 lg:mt-24"
      />

      <less-effort-and-managment class="my-16 lg:my-24" />
    </div>
    <client-only>
      <from-developer-for-developer
        v-if="showDeveloperForDeveloper"
        class="mb-16 lg:mb-24"
      />
    </client-only>

    <div class="container">
      <client-only>
        <what-will-it-cost
          v-if="showWhatWillItCost"
          class="mb-16 lg:mb-24 lg:px-12"
        />
      </client-only>

      <lets-get-started
        v-if="!showSubscribePage"
        class="mb-16 lg:mb-24"
      />
    </div>
  </div>
</template>
