<script setup>
import {useFeatureFlags} from '~/store/featureFlags'

const localePath = useLocalePath();

const showSubscribePage = computed(() => {
  return useFeatureFlags().getFeatureFlags.website_subscription_page;
});
</script>

<template>
  <div class="how-do-i-get-started">
    <h2 v-text="$t('WHODOIGETSTARTED')" />
    <div class="boxes">
      <div class="divider" />
      <div class="box-item">
        <div
          class="number-md"
          v-text="1"
        />
        <div class="image-wrapper bg-error-500">
          <img
            src="/images/website_home_lets_get_started.png"
            alt="let's get started"
          >
        </div>
        <div
          class="number"
          v-text="1"
        />
        <div class="title-text">
          <div class="circle-1" />
          <div class="circle-2" />
          <div class="circle-3" />
          <h4 v-text="$t('SIGNUP')" />
          <p v-text="$t('WEPROMISEITISQUICK')" />
          <div>
            <nuxt-link
              :to="
                localePath({ name: showSubscribePage ? 'subscribe' : 'signup' })
              "
            >
              <sc-button
                primary
                class="mt-6"
                :label="showSubscribePage ? $t('SUBSCRIBENOW') : $t('TRYITNOW')"
              />
            </nuxt-link>
          </div>
        </div>
      </div>
      <div class="box-item mt-14 xl:mt-20">
        <div
          class="number-md"
          v-text="2"
        />
        <div class="title-text relative order-3 md:order-1">
          <h4 v-text="$t('CREATEASHOP')" />
          <p v-text="$t('WITHOUTTHENEEDTOLEAVE')" />
          <svg-create-shop-line class="line" />
        </div>
        <div
          class="number order-2"
          v-text="2"
        />
        <div class="image-wrapper bg-warning-500 order-1 md:order-3">
          <img
            src="/images/website_home_welcome.png"
            alt="let's get started"
          >
        </div>
      </div>
      <div class="box-item mt-14 xl:mt-20">
        <div
          class="number-md"
          v-text="3"
        />
        <div class="image-wrapper bg-success-600">
          <img
            src="/images/website_home_all_products.png"
            alt="let's get started"
          >
        </div>
        <div
          class="number"
          v-text="3"
        />
        <div class="title-text">
          <h4 v-text="$t('CONNECTWITHASTOREFRONT')" />
          <p v-text="$t('IFYOUARENOTADEVELOPER')" />
          <nuxt-link :to="localePath({ name: 'index' })">
            <sc-button
              outlined
              class="mt-6"
              :label="$t('CONTACTUS')"
            />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/functions";
.how-do-i-get-started {
}

h2 {
  @apply text-center text-secondary-400 font-bold;
}

.boxes {
  @apply mt-8 xl:mt-12 xl:py-10 mx-auto relative;
  @media (min-width: 1024px) {
    max-width: rem(880px);
  }
}

.divider {
  @apply absolute top-0 bottom-0 w-px bg-secondary-200 left-7 md:left-1/2;
}

.box-item {
  @apply grid grid-cols-12 items-center relative;
}

.image-wrapper {
  @apply flex items-center justify-center col-start-4 col-span-8 md:col-span-5;
  border-radius: rem(15px);
  width: rem(352px);
  height: rem(352px);
  @media (max-width: 1024px) {
    @apply w-48 h-48 p-2;
  }

  img {
    width: rem(320px);
    height: auto;
  }
}

.number {
  @apply w-12 h-12 xl:w-16 xl:h-16 border border-solid border-secondary-200 rounded-full hidden md:flex items-center justify-center mx-auto col-span-2 bg-white;
  font-size: rem(30px);
  line-height: rem(32px);
  @media (max-width: 768px) {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}

.number-md {
  @apply absolute top-1/4 left-1 w-12 h-12 xl:w-16 xl:h-16 border border-solid border-secondary-200 rounded-full flex items-center col-span-2 justify-center md:hidden bg-white;
  font-size: rem(30px);
  line-height: rem(32px);
  @media (max-width: 768px) {
    font-size: rem(20px);
    line-height: rem(30px);
  }
}

.title-text {
  @apply col-start-4 col-span-8 md:col-span-5;
}

h4 {
  @apply text-secondary-400 font-semibold mt-4 md:mt-0;
}

p {
  @apply mt-3 md:mt-4;
}

.circle-1 {
  @apply absolute bg-primary-100 rounded-full hidden lg:block;
  width: rem(48px);
  height: rem(49px);
  top: rem(102px);
  left: rem(720px);
}

.circle-2 {
  @apply absolute bg-primary-100 rounded-full hidden lg:block;
  width: rem(18px);
  height: rem(19px);
  top: rem(109px);
  left: rem(826px);
}

.circle-3 {
  @apply absolute bg-primary-400 rounded-full hidden lg:block;
  width: rem(21px);
  height: rem(21px);
  top: rem(86px);
  left: rem(796px);
}

.line {
  @apply absolute hidden xl:block;
  top: rem(29px);
  left: rem(13px);
}
</style>
